import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/assessment-info',
    name: 'assessment-info',
    component: () => import(/* webpackChunkName: "assessment-info" */ '../views/assessment/Info.vue')
  },
  {
    path: '/assessment',
    name: 'assessment',
    component: () => import(/* webpackChunkName: "assessment" */ '../views/assessment/Solution.vue')
  },
  {
    path: '/leadership',
    name: 'leadership',
    component: () => import(/* webpackChunkName: "leadership" */ '../views/leadership/Solution.vue')
  },
  {
    path: '/mental-health',
    name: 'mental-health',
    component: () => import(/* webpackChunkName: "mental-health" */ '../views/mentalHealth/Solution.vue')
  },
  {
    path: '/introduction-education',
    name: 'introduction-education',
    component: () => import(/* webpackChunkName: "introduction-education" */ '../views/education/IntroductionEducation.vue')
  },
  {
    path: '/education-schedule',
    name: 'education-schedule',
    component: () => import(/* webpackChunkName: "education-schedule" */ '../views/education/EducationSchedule.vue')
  },
  {
    path: '/apply-education/:code',
    name: 'apply-education',
    component: () => import(/* webpackChunkName: "apply-education" */ '../views/education/ApplyEducation.vue')
  },
  {
    path: '/apply-education-completed/:code',
    name: 'apply-education-completed',
    component: () => import(/* webpackChunkName: "apply-education-completed" */ '../views/education/ApplyEducationCompleted.vue')
  },

  // cs
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "notice" */ '../views/cs/Notice.vue')
  },
  {
    path: '/notice/:code',
    name: 'notice-detail',
    component: () => import(/* webpackChunkName: "notice-detail" */ '../views/cs/NoticeDetail.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/cs/Faq.vue')
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import(/* webpackChunkName: "inquiry" */ '../views/cs/Inquiry.vue')
  },
  {
    path: '/hive',
    name: 'hive',
    component: () => import(/* webpackChunkName: "hive" */ '../views/cs/Hive.vue')
  },
  {
    path: '/hive/:code',
    name: 'hive-detail',
    component: () => import(/* webpackChunkName: "hive-detail" */ '../views/cs/HiveDetail.vue')
  },


  // sign
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/sign/Login.vue')
  },
  
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/* webpackChunkName: "sign-up" */ '../views/sign/SignUp.vue')
  },
  
  {
    path: '/find-info',
    name: 'find-info',
    component: () => import(/* webpackChunkName: "find-info" */ '../views/sign/FindInfo.vue')
  },
  {
    path: '/chg-pw',
    name: 'chg-pw',
    component: () => import(/* webpackChunkName: "chg-pw" */ '../views/sign/ChgPw.vue')
  },
  {
    path: '/link/:code',
    name: 'link',
    component: () => import(/* webpackChunkName: "link" */ '../views/sign/LinkConfirm.vue')
  },


  // 마이페이지
  {
    path: '/mypage',
    redirect: '/mypage/my-info-login'
  },
  {
    path: '/mypage/my-info/login',
    name: 'mypage-my-info-login',
    component: () => import(/* webpackChunkName: "mypage-my-info-login" */ '../views/mypage/MyInfoLogin.vue')
  },
  {
    path: '/leave',
    name: 'mypage-leave',
    component: () => import(/* webpackChunkName: "mypage-leave" */ '../views/mypage/Leave.vue')
  },
  {
    path: '/mypage/my-info',
    name: 'mypage-my-info',
    component: () => import(/* webpackChunkName: "mypage-my-info" */ '../views/mypage/MyInfo.vue')
  },
  {
    path: '/mypage/my-info/additional-info',
    name: 'mypage-additional-info',
    component: () => import(/* webpackChunkName: "mypage-additional-info" */ '../views/mypage/AdditionalInfo.vue')
  },
  {
    path: '/mypage/my-inquiry',
    name: 'mypage-my-inquiry',
    component: () => import(/* webpackChunkName: "mypage-my-inquiry" */ '../views/mypage/MyInquiry.vue')
  },
  {
    path: '/mypage/my-inquiry/:code',
    name: 'mypage-my-inquiry-detail',
    component: () => import(/* webpackChunkName: "mypage-my-inquiry-detail" */ '../views/mypage/MyInquiryDetail.vue')
  },
  {
    path: '/mypage/my-inquiry/form',
    name: 'mypage-my-inquiry-form',
    component: () => import(/* webpackChunkName: "mypage-my-inquiry-form" */ '../views/mypage/MyInquiryForm.vue')
  },
  {
    path: '/mypage/my-cash',
    name: 'mypage-my-cash',
    component: () => import(/* webpackChunkName: "mypage-my-cash" */ '../views/mypage/MyCash.vue')
  },



  // 구매/실시
  {
    path: '/order/:idx',
    name: 'order-detail',
    component: () => import(/* webpackChunkName: "order-detail" */ '../views/project/OrderDetail.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import(/* webpackChunkName: "purchase" */ '../views/project/Purchase.vue')
  },
  {
    path: '/conduct-diagnosis',
    name: 'conduct-diagnosis',
    component: () => import(/* webpackChunkName: "conduct-diagnosis" */ '../views/project/ConductDiagnosis.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '../views/project/ProjectAll.vue')
  },
  {
    path: '/project-done',
    name: 'project-done',
    component: () => import(/* webpackChunkName: "project-done" */ '../views/project/ProjectDone.vue')
  },
  {
    path: '/project/:code',
    name: 'project-detail',
    component: () => import(/* webpackChunkName: "project-detail" */ '../views/project/ProjectDetail.vue')
  },
  {
    path: '/project/edit/:code',
    name: 'project-edit',
    component: () => import(/* webpackChunkName: "project-edit" */ '../views/project/ProjectEdit.vue')
  },
  {
    path: '/project-create/link',
    name: 'project-create-link',
    component: () => import(/* webpackChunkName: "project-create-link" */ '../views/project/ProjectCreateLink.vue')
  },
  {
    path: '/project-create/code',
    name: 'project-create-code',
    component: () => import(/* webpackChunkName: "project-create-code" */ '../views/project/ProjectCreateCode.vue')
  },
  {
    path: '/project-create/add/:code',
    name: 'project-add-code',
    component: () => import(/* webpackChunkName: "project-add-code" */ '../views/project/ProjectAddCode.vue')
  },


  // company
  {
    path: '/vision-house',
    name: 'vision-house',
    component: () => import(/* webpackChunkName: "vision-house" */ '../views/company/VisionHouse.vue')
  },
  {
    path: '/client',
    name: 'client',
    component: () => import(/* webpackChunkName: "client" */ '../views/company/Client.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/company/Team.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "partner" */ '../views/company/Partner.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '../views/company/Map.vue')
  },


  // web link
  {
    path: '/share/:code',
    name: 'share-password',
    component: () => import(/* webpackChunkName: "share-password" */ '../views/webLink/Password.vue')
  },
  {
    path: '/share/detail/:code',
    name: 'share-project-detail',
    component: () => import(/* webpackChunkName: "share-project-detail" */ '../views/webLink/ProjectDetail.vue')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if( !to.hash ){
      document.querySelector(".app_wrap").scrollTop = 0;
      return { top: 0 };
    }
  }
});



export default router
