<template>
    <div class="app_wrap">
      <router-view :key="$route.fullPath"/>
    </div>
</template>

<style lang="scss">
@import 'assets/css/all.css'; //폰트어썸
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'assets/css/custom-bootstrap.scss';
@import 'assets/css/common.scss'; // bootstrap theme.css included
@import 'assets/css/style.css';
@import 'assets/css/style.scss';
// @import 'vue3-carousel/dist/carousel.css';

// @import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@font-face {
  font-family: 'Basier Circle';
	font-style: normal;
	font-weight: 400;
  src: url('./assets/fonts/BasierCircle-Regular.woff2');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	src: url('./assets/fonts/conveted/NotoKR-Regular/notokr-regular.woff2') format("woff2");
	unicode-range: U+1100-11FF,U+3130-318F,U+A960-A97F,U+AC00-D7A3,U+D7B0-D7FF
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 300;
	src: url('./assets/fonts/conveted/NotoKR-Light/notokr-light.woff2') format("woff2");
	unicode-range: U+1100-11FF,U+3130-318F,U+A960-A97F,U+AC00-D7A3,U+D7B0-D7FF
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: bold;
	font-weight: 600;
	src: url('./assets/fonts/conveted/NotoKR-Bold/notokr-bold.woff2') format("woff2");
	unicode-range: U+1100-11FF,U+3130-318F,U+A960-A97F,U+AC00-D7A3,U+D7B0-D7FF
}

#app {
  // font-family: 'Red Hat Display','Urbanist','Noto Sans KR', Helvetica, Arial, sans-serif;
  font-family: 'Basier Circle','Urbanist','Noto Sans KR', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  word-break: keep-all;
  height: 100vh !important;
}
.app_wrap{
  height: 100vh;
  overflow-y: auto;
}
.overflow-y-scroll{
  overflow-y: scroll;
}
.bg-primary{
  background-color: #008080 !important;
}
.text-primary{
  color: #006666 !important;
}
.text-primary-1{
  color: #ed7d31 !important;
}
.text-primary-2{
  color: #f05b6e !important;
}
.btn-primary{
  background-color: teal !important;
  border-color: teal !important;
}
.btn-primary:hover{
  background-color: #066!important;
  border-color: #066!important;
}
.sticky .btn-outline-primary{
  color: #008080 !important;
}
.sticky .btn-outline-white{
  border-color: #006666;
  color: #008080 !important;
}
.btn-outline-white{
  padding-left: 30px;
  padding-right: 30px;
  transition: all 0.3s ease 0.1s;
  border-width: 0;
  text-decoration: none;
  box-shadow: none;
  text-transform: uppercase;
  line-height: 2.5;
  font-weight: 700;
  // letter-spacing: 1px;
  color: #fff;
  position: relative;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 0;
  overflow: hidden;

  border-color: #fff;
  background-color: transparent !important;
}
.btn-outline-white:hover{
  border-color: #aff1f1;
  color: #aff1f1;
}
.btn-outline-primary{
  padding-left: 30px;
  padding-right: 30px;
  transition: all 0.3s ease 0.1s;
  border-width: 0;
  text-decoration: none;
  box-shadow: none;
  text-transform: uppercase;
  line-height: 2.5;
  font-weight: 700;
  // letter-spacing: 1px;
  color: #fff;
  position: relative;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 0;
  overflow: hidden;

  border-color: #008080 !important;
  background-color: transparent !important;
}
.btn-outline-primary:hover{
  color: #008080;
}
.carousel__pagination{
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%,-50%);
}
.carousel__pagination-button::after{
    width: 15px !important;
    height: 15px !important;
    background-color: rgba(255, 255, 255, 0.425)!important;
    border-radius: 50% !important;
}
.carousel__pagination-button--active::after{
    background-color: rgba(255, 255, 255, 0.9)!important;
}

.assessment-info .bg-custom{
  padding: 45px;
  color: #05475C;
  background: linear-gradient(#C6DFE0, #C9E3E3, #B3DADA);
  box-shadow: 10px 10px 23px 0 rgba(15, 46, 56, 0.2);
  position: relative;
  overflow: hidden;

  &>*{
    position: relative;
    z-index: 2;
  }

  &::before{
    content: "";
    background: linear-gradient(#fff, #fff, #fff);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.3s;
  }

  &:hover{
      // background: linear-gradient(#C6DFE0, #C9E3E3, #B3DADA);
      &::before{
        opacity: 0;
      }
  }
}

.hover-target .hover-content{
  display: none;
}
.hover-target:hover .hover-content{
  display: block;
}
.input-with-icon {
  position: relative;
}

.input-with-icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.min-w-px-130 {
  min-width: 130px;
}
@media screen and (max-width:991px) {
  .flex-wrap-md{
    flex-wrap: wrap !important;
  }
  .w-100-sm{
    width: 100% !important;
  }
}

@media screen and (max-width:768px){
  .m-around{
      /*content:'';
      display: block;
      min-width: 130px;*/
      justify-content: space-evenly !important;
  }
}
// 수료증 css

.print_certi_box{
  page-break-inside: avoid; // 해당 엘리먼트의 내부에서 페이지 넘김을 금지 (pdf에 2페이지가 생기는 걸 방지하기 위함)
  font-size: 2em;
  
  .fs-px-ssm{
    font-size: .6em;
  }
  .fs-px-sm{
    font-size: .8em;
  }
}

.web_certi_box, .print_certi_box{
  i{
      color: #23676b;
  }
}
.web_certi_box{
  .certi_img{
    min-width: 750px;
    min-height: 530.27px;
  }
  
  .certi_img+div{
      top: 195px !important;
      left: 380px !important;
      transform: translateX(-50%);
  }
}

.print_certi_box{
  .certi_img{
      min-width:1123px;
      min-height:794px;
      // width: 750px !important;
      // height: 530.27px;
  }
  .certi_img+div{
      top: 315px !important;
      left: 50% !important;
      transform: translateX(-50%);
  }
}
.web_certi_box .h1, .web_certi_box h1{
    margin: 23px 0 0 !important;
    font-size: 35px;
    letter-spacing: 5px;
    padding-bottom: 20px !important;
    font-weight: 400;
    color : #23676b;
}

.print_certi_box .h1, .print_certi_box h1{
    margin: 25px 0 0 !important;
    font-size: 45px;
    letter-spacing: 5px;
    padding-bottom: 30px !important;
    color : #23676b
}

@media screen and (max-width:767px){ 
    .certi_docu, .certi_docu *{
        font-size: .8em !important;
    }
    .certi_docu h1{
        font-size: 2em !important;
        padding-bottom: 8px !important;
        margin-top: 4px !important;
    }
    .certi_course{
        margin-bottom: 5px !important;
    }
    .certi_content{
        padding-top: 20px !important;
    }
}
.overlay_mask {
  width:100%;height:100vh;position: fixed;top:0px;left:0px;background-color: #18181850;z-index: 100000;
}

.loading_circle{
  position: fixed;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem); 
}
.table>:not(caption)>*>*{
  background-color: transparent;
}
.pt-header>section.lgray-bg,
.hero-area+div{
  min-height: calc(100vh - 90px - 416px);
}
</style>